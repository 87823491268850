<template>
    <div @click="closeModal()" class="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-45 z-[800] flex flex-col h-full py-8 overflow-y-auto">
        <div @click.stop class="bg-slate-800 md:p-6 p-4 shadow-xl shadow-slate-950
            rounded-sm border border-white border-opacity-10
            min-w-[320px] w-[80%] max-w-[500px] mx-auto my-auto">
            
            <div class="flex flex-row justify-between">
                <h2 class="font-bold mb-3">Phantom Wallet Connection</h2>
                
                <div @click="closeModal()" class="p2 cursor-pointer">
                    <img src="@/assets/set/close.svg" class="w-5 h-5">
                </div>
            </div>

            <div v-if="isLoading" class="flex flex-col justify-center gap-3 border border-slate-600 p-5 rounded-xl mb-4 text-center min-h-[250px]">
                <div class="spinner-32 mx-auto"></div>
                <h3 class="">Verifying authentication...</h3>
            </div>
            <div v-else>
                <div class="mb-5 text-lg">
                    {{ status }}
                </div>

                <div v-if="error" class="flex flex-col gap-3 border border-red-500 border-opacity-50 p-5 rounded-xl mb-4 bg-red-500 bg-opacity-10">
                    <h3 class="font-bold text-red-400">Error</h3>
                    <div class="text-red-300">{{ error }}</div>
                </div>

                <div class="flex justify-end">
                    <button @click="closeModal()" class="b3 bc">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/api';

export default {
    data() {
        return {
        isLoading: false,
        status: 'Processing response from Phantom...', // Please wait while we confirm your Phantom wallet connection.
        error: null
        };
    },
    // computed: {
    //     // ...mapState('modals', ['openAuthPhantom']),
    //     // showModal() {
    //     //     return this.openAuthPhantom;
    //     // },
    // },
    mounted() {
        this.processResponse();
    },
    methods: {

        ...mapActions(['showInformation']),

        ...mapActions('modals', ['hideAuthPhantomModal']),

        ...mapActions('phantom', ['handleConnectResponse', 'handleSignMessageResponse']),
        
        closeModal() {
            this.hideAuthPhantomModal();
        },

        async processResponse() {
            try {
                // Get URL parameters
                const urlParams = new URLSearchParams(window.location.search);
                console.log('Processing Phantom response with params:', Object.fromEntries(urlParams));
                
                // Check for error response
                const errorCode = urlParams.get('errorCode');
                const errorMessage = urlParams.get('errorMessage');
                if (errorCode || errorMessage) {
                    throw new Error(errorMessage || 'Unknown error from Phantom');
                }

                // Check for required parameters
                const data = urlParams.get('data');
                const nonce = urlParams.get('nonce');
                const session = urlParams.get('app_session');
                const phantom_encryption_public_key = urlParams.get('phantom_encryption_public_key');
                
                // !! ++ IF THERE WE HAVE RECEIVED A NEW SESSION 
                // (MOST CASES IT IS NEW TAB OPENED IN SAFARI)
                // MAKE SURE WE WILL SET IT TO THE STORE OF THIS SESSION
                // AND UPDATE THE SESSION OF A USER, SAME FOR SOLFLARE!
                // OK, done in phantom.js

                // open dash?
                if (!data || !nonce || !session) {
                    console.error('Missing parameters:', { data, nonce, session });
                    throw new Error('Missing required parameters');
                }

                // Handle connect response
                if (phantom_encryption_public_key) {
                    this.status = 'Processing connection response...';
                    await this.handleConnectResponse({
                        phantom_encryption_public_key,
                        data,
                        nonce,
                        session // is app_session not wallet session
                    });
                } 
                // Handle sign message response
                else {
                    this.status = 'Processing signature response...';
                    // alert('processResponse Processing signature response...'.JSON.stringify(data)+' '+JSON.stringify(nonce)+' '+JSON.stringify(session));
                    await this.handleSignMessageResponse({
                        data,
                        nonce,
                        session // is app_session not wallet session
                    });

                    // Redirect back to the home page - that is Dash?
                    this.$router.push({ name: 'dash' });
                    
                }
                // HOW TO DEFINE TRANSACTION RESPONSE?
                this.hideAuthPhantomModal();


            } catch (error) {
                console.error('Error processing Phantom response:', error);
                
                // Try to parse error message from axios response or JSON string
                let errorMessage = error.message;
                try {
                    if (error.response?.data) {
                        // Handle axios error response
                        errorMessage = error.response.data.message || error.response.data;
                    } else if (typeof error.message === 'string' && error.message.includes('{')) {
                        // Handle JSON string error
                        const errorJson = JSON.parse(error.message);
                        errorMessage = errorJson.message || errorMessage;
                    }
                } catch (e) {
                    // If parsing fails, use original error message
                }

                this.error = errorMessage;
                this.status = 'Connection failed';
                this.showInformation({
                    message: 'Error processing Phantom response: ' + errorMessage,
                    type: 'error'
                });
                // setTimeout(() => {
                //     window.location.href = '/';
                // }, 1000);
                // this.hideAuthPhantomModal();
            }
        }
    }

};
</script>
    