<template>
    <div @click="closeModal()" class="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-45 z-[800] flex flex-col h-full py-8 overflow-y-auto">
        <div @click.stop class="bg-slate-800 md:p-6 p-4 shadow-xl shadow-slate-950
            rounded-sm border border-white border-opacity-10
            min-w-[320px] w-[80%] max-w-[500px] mx-auto my-auto">
            
            <div class="flex flex-row justify-between">
                <h2 class="font-bold mb-3">Telegram Login</h2>
                <div @click="closeModal()" class="p2 cursor-pointer">
                    <img src="@/assets/set/close.svg" class="w-5 h-5">
                </div>
            </div>

            <div v-if="isLoading" class="flex flex-col justify-center gap-3 border border-slate-600 p-5 rounded-xl mb-4 text-center min-h-[250px]">
                <div class="spinner-32 mx-auto"></div>
                <h3 class="">Verifying authentication...</h3>
            </div>
            <div v-else>
                <div class="mb-5 text-lg">
                    Please wait while we confirm your Telegram authentication.
                </div>

                <div v-if="error" class="flex flex-col gap-3 border border-red-500 border-opacity-50 p-5 rounded-xl mb-4 bg-red-500 bg-opacity-10">
                    <h3 class="font-bold text-red-400">Error</h3>
                    <div class="text-red-300">{{ error }}</div>
                </div>

                <div class="flex justify-end">
                    <button @click="closeModal()" class="b3 bc">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/api';

export default {
  data() {
    return {
      isLoading: false,
      error: null
    };
  },
  computed: {
    ...mapState('modals', ['modals']),
    showModal() {
      return this.modals.AuthTelegram.show;
    },
    authCode() {
      return this.modals.AuthTelegram.code;
    }
  },
  watch: {
    authCode: {
      immediate: true,
      handler(code) {
        if (code) {
          this.verifyAuth();
        }
      }
    }
  },
  methods: {
    ...mapActions(['showInformation']),
    ...mapActions('playwallet', ['fetchWallets']),
    
    closeModal() {
      this.$store.commit('modals/MODAL_AUTH_TELEGRAM_HIDE');
    },

    async verifyAuth() {
      if (!this.authCode) {
        this.error = 'No authentication code provided';
        return;
      }

      this.isLoading = true;
      this.error = null;

      try {
        const codeRegex = /^[a-zA-Z0-9]{42}$/;
        if (!codeRegex.test(this.authCode)) {
          throw new Error('Invalid authentication code format.');
        }
    
        const response = await api.post('auth/telegram/verify', {
          data: { 
            code: this.authCode
          }
        });

        if (response.data.result === 'success') {
          this.showInformation({ type: 'success', message: 'Authentication successful' });
          
          if (response.data.user_data) {
            this.$store.commit('SET_USER', response.data.user_data);
          }

          if (response.data.jwt && response.data.refresh_token) {
            this.$store.commit('SET_JWT', response.data.jwt);
            this.$store.commit('SET_REFRESH_TOKEN', response.data.refresh_token);
          }

          this.fetchWallets();
          this.closeModal();
          this.$emit('showAccess');
          
        } else {
            // throw new Error(response.data.message || 'Authentication failed');
          throw new Error('Authentication not completed. Please try again.');
        }
      } catch (error) {
        // this.error = error.message || 'Failed to verify authentication';
        this.error = 'Authentication not completed. Please try again.';
        this.showInformation({ 
          type: 'error', 
          message: this.error
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
    