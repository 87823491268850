import axios from 'axios';
import api from '@/api';
import { handleError } from '../../utils/errors';

// Add this at the top of the file
let isRefreshing = false;
let refreshPromise = null;

const actions = {

    // blocked for miniaps
    // async authTelegramWebapp({ commit, rootState, dispatch }, teleData) {
    //     alert('authTelegramWebapp with data: '+JSON.stringify(teleData));
    //     try {
    //       if (!teleData.initData) {
    //           alert('No initData provided');
    //             throw new Error('No initData provided');
    //     }
    //       console.log('[store/auth/authTelegramWebapp] teleData '+JSON.stringify(teleData));
    //       const response = await axios.post(process.env.VUE_APP_API_URL + 'auth/telegram/webapp', {
    //         data: teleData,
    //       });
    //       // alert('authMiniapp response '+JSON.stringify(response.data));
    //       if (response.data.status === 'success') {
    //         // SET JWT like this:

    //         commit('SET_USER', response.data.user_data);
    //         if(response.data.user_auth) {
    //             commit('SET_JWT', response.data.jwt);
    //             commit('SET_REFRESH_TOKEN', response.data.refresh_token );
    //         }
    //         if (response.data.jwt && response.data.refresh_token) {
    //             commit('SET_JWT', response.data.jwt, { root: true });
    //             commit('SET_REFRESH_TOKEN', response.data.refresh_token, { root: true });
    //         }
            
    //         //   commit('SET_AUTH_JWT', response.data.auth_jwt);
    //         //   commit('SET_AUTH_RT', response.data.auth_rt);
    //         //   dispatch('getUserData', response.data.user_data, { root: true });
    //       } else {
    //         throw new Error(response.data.message || 'Authentication failed');
    //       }
    //     } catch (error) {
    //       console.error('Error in authMiniapp:', error);
    //       dispatch('showInformation', {
    //         message: error.message || 'Authentication failed',
    //         type: 'error'
    //       }, { root: true });
    //       dispatch('logout');
    //       throw error;
    //     }
    // },

    async afterAuth ({ dispatch }) {
        // get list of wallets
        dispatch('playwallet/fetchWallets', null, { root: true });
        dispatch('modals/showAccessModal', null, { root: true });
    },

    async doRefreshJWT({ commit, dispatch, state }) {
        // If a refresh is already in progress, return the existing promise
        if (isRefreshing) {
            return refreshPromise;
        }

        isRefreshing = true;
        refreshPromise = new Promise(async (resolve, reject) => {
            try {
                console.log('[store/doRefreshJWT] - Start');
                const response = await axios.post(process.env.VUE_APP_API_URL+'auth/refresh', 
                {
                    pid: process.env.VUE_APP_PID,
                    refresh_token: state.refreshToken
                });
                console.log('[store/doRefreshJWT] - Response:', JSON.stringify(response.data));
                
                if (response.data.result === 'success') {
                    if (response.data.jwt) {
                        commit('SET_JWT', response.data.jwt);
                        commit('SET_REFRESH_TOKEN', response.data.refresh_token);
                        console.log('[store/doRefreshJWT] Success - JWT and new refresh token set in store.'+"\n"+
                            'JWT= ' + response.data.jwt +"\n"+
                            'Refresh= ' + response.data.refresh_token
                        );
                        resolve({ jwt: response.data.jwt, result: 'success' });
                    } else {
                        console.error('[store/doRefreshJWT] ERROR - No JWT in successful response');
                        reject({ result: 'error', message: 'No JWT in response' });
                    }
                } else {
                    console.error('[store/doRefreshJWT] ERROR - Refresh token request failed:', response.data);
                    reject({ result: 'error', message: 'Refresh failed' });
                }
            } catch (error) {
                console.error('[store/doRefreshJWT] Failed to refresh token:', error);
                dispatch('showInformation', {
                    message: 'Unable to refresh authorization token. Please reconnect the wallet.',
                    type: 'error'
                });
                commit('CLEAR_AUTH');
                reject(error);
            } finally {
                isRefreshing = false;
                refreshPromise = null;
            }
        });

        return refreshPromise;
    },

    //* Wallet Auth *//

    async authWalletSignInit({ state }, { walletAddress, walletType, walletNetwork, walletProvider }) {
        // alert('[store/authWalletSignInit]: '+walletAddress+' '+walletType+' '+walletNetwork+' '+walletProvider);
        try {
            const response = await api.post('auth/wallet/init', {
                data: {
                    wallet_address: walletAddress,
                    wallet_type: walletType, // 'evm' | 'sol',
                    wallet_network: walletNetwork, // 1 - eth, 2 - sol, 3 - base, 4 - boba, 5 - linea, 6 - ton, 7 - tron
                    wallet_provider: walletProvider, // 'metamask' | 'solflare' | 'phantom',
                    utm_source: state.utmSource,
                    utm_campaign: state.utmCampaign,
                    utm_medium: state.utmMedium,
                    code_invite: state.codeInvite
                }
            });
            // alert('authWalletSignInit response='+JSON.stringify(response.data));
            console.log('[STORE/auth/authWalletSignInit] Response: '+JSON.stringify(response.data));
            return response.data.sign_message;
        } catch (err) {
            const errorMessage = handleError(err, '[STORE/auth/authWalletSignInit]');
            throw new Error(errorMessage);
        }
    },

    async authWalletSignVerify({ commit, dispatch }, { walletAddress, signedMessage, walletType, walletNetwork, walletProvider }) {
        try {

            let sendSignature = '';
            // alert('walletType='+walletType);
            if(walletType=='sol') {
                sendSignature = signedMessage.signature;
            }  else {
                sendSignature = signedMessage;
            }
            
            const response = await api.post('auth/wallet/verify', {
                data: {
                    wallet_address: walletAddress,
                    signed_value: sendSignature, // signedMessage, //.signature,
                    wallet_type: walletType, // 'evm' | 'sol',
                    wallet_network: walletNetwork, // 1 - eth, 2 - sol, 3 - base, 4 - boba, 5 - linea, 6 - ton, 7 - tron
                    wallet_provider: walletProvider //  'metamask' | 'solflare' | 'phantom',
                }
            });

            // alert('authWalletSignVerify response='+JSON.stringify(response.data));
            if (response.data.status === 'success') {

                commit('SET_USER', response.data.user_data);
                if(response.data.user_auth) {
                    commit('SET_JWT', response.data.jwt);
                    commit('SET_REFRESH_TOKEN', response.data.refresh_token );
                }
                if (response.data.jwt && response.data.refresh_token) {
                    commit('SET_JWT', response.data.jwt, { root: true });
                    commit('SET_REFRESH_TOKEN', response.data.refresh_token, { root: true });
                }

                dispatch('afterAuth');
               // hideAuth shall be done automatically as user is changed to true
                
            }
            return response.data;

        } catch (err) {
            const errorMessage = handleError(err, '[STORE/auth/authWalletSignVerify]');
            throw new Error(errorMessage);
        }
    },

    //* Connect Wallet in Settings *//

    async authWalletSetInit({ state }, { walletAddress, walletType, walletNetwork, walletProvider }) {
        // alert('[store/authWalletSetInit]: '+walletAddress+' '+walletType+' '+walletNetwork+' '+walletProvider);
        try {
            console.log('[STORE/auth/authWalletSetInit] Start: '+walletAddress+' '+walletType+' '+walletNetwork+' '+walletProvider);
            const response = await api.post('user/settings/wallet/init', {
                data: {
                    wallet_address: walletAddress,
                    wallet_type: walletType, // 'evm' | 'sol',
                    wallet_network: walletNetwork, // 1 - eth, 2 - sol, 3 - base, 4 - boba, 5 - linea, 6 - ton, 7 - tron
                    wallet_provider: walletProvider, // 'metamask' | 'solflare' | 'phantom',
                    utm_source: state.utmSource,
                    utm_campaign: state.utmCampaign,
                    utm_medium: state.utmMedium,
                    code_invite: state.codeInvite
                }
            });
            console.log('[STORE/auth/authWalletSetInit] Response: '+JSON.stringify(response.data));
            return response.data.sign_message;
        } catch (err) {
            const errorMessage = handleError(err, '[STORE/auth/authWalletSetInit]');
            throw new Error(errorMessage);
        }
    },

    async authWalletSetVerify({ commit, dispatch }, { walletAddress, signedMessage, walletType, walletNetwork, walletProvider }) {
        try {
            // console.log('[STORE/auth/authWalletSetVerify] Start: '+walletAddress+' '+signedMessage+' '+walletType+' '+walletNetwork+' '+walletProvider);
            // alert('signedMessage='+JSON.stringify(signedMessage));
            // alert('signedMessage signature='+signedMessage.signature);
            let sendSignature = '';
            // alert('walletType='+walletType);
            if(walletType=='sol') {
                sendSignature = signedMessage.signature;
            }  else {
                sendSignature = signedMessage;
            }
            const response = await api.post('user/settings/wallet/verify', {
                data: {
                    wallet_address: walletAddress,
                    signed_value: sendSignature, // signedMessage, //.signature,
                    wallet_type: walletType, // 'evm' | 'sol',
                    wallet_network: walletNetwork, // 1 - eth, 2 - sol, 3 - base, 4 - boba, 5 - linea, 6 - ton, 7 - tron
                    wallet_provider: walletProvider //  'metamask' | 'solflare' | 'phantom',
                }
            });
            // dispatch('...', null, { root: true });
            return response.data;
        } catch (err) {
            const errorMessage = handleError(err, '[STORE/auth/authWalletSetVerify]');
            throw new Error(errorMessage);
        }
    },

    async authWalletDelete({ commit, dispatch }, { walletAddress, walletType, walletNetwork, walletProvider }) {
        try {
            // alert('authWalletDelete');
            // console.log('[STORE/auth/authWalletDelete] Start: '+walletAddress+' '+signedMessage+' '+walletType+' '+walletNetwork+' '+walletProvider);
            const response = await api.post('user/settings/wallet/delete', {
                data: {
                    wallet_address: walletAddress,
                    wallet_type: walletType, // 'evm' | 'sol',
                    wallet_network: walletNetwork, // 1 - eth, 2 - sol, 3 - base, 4 - boba, 5 - linea, 6 - ton, 7 - tron
                    wallet_provider: walletProvider //  'metamask' | 'solflare' | 'phantom',
                }
            });
            // dispatch('...', null, { root: true });
            return response.data;
        } catch (err) {
            const errorMessage = handleError(err, '[STORE/auth/authWalletDelete]');
            throw new Error(errorMessage);
        }
    },

    // async notifyWalletConnection({ state, dispatch }, { publicKey, walletType, walletNetwork, walletProvider }) {
    //     try {
    //     await api.post('auth/wallet/notify', {
    //         data: {
    //         public_key: publicKey,
    //         wallet_type: walletType,
    //         wallet_network: walletNetwork,
    //         wallet_provider: walletProvider,
    //         session: state.sessionToken
    //         }
    //     });
    //     } catch (err) {
    //     console.error('Failed to notify backend of wallet connection:', err);
    //     }
    // },

    //* Logout *//

    logout({ commit, dispatch }) {
        console.log('[STORE/auth/logout] - Clear auth!');
        commit('CLEAR_AUTH');
        dispatch('showInformation', {
            message: 'You have been logged out',
            type: 'info'
        }, { root: true });
    },

};

// Axios interceptor
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       try {
//         const newToken = await store.dispatch('refreshToken');
//         originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
//         return api(originalRequest);
//       } catch (refreshError) {
//         return Promise.reject(refreshError);
//       }
//     }
//     return Promise.reject(error);
//   }
// );

// Use this api instance for all axios requests
// store.api = api;

export default actions;