<template>

    <div class="fixed inset-0 max-md:py-8 bg-black backdrop-blur-sm bg-opacity-45 z-[800] flex flex-col h-full overflow-y-auto" @click="hideAccess()">
    <!-- <div class="fixed inset-0 max-md:py-8 bg-slate-900 backdrop-blur-sm bg-opacity-65 z-[800] flex flex-col h-full overflow-y-auto" @click="hideAccess()"> -->

        <div class="flex flex-col p-5 mk:p-8 gap-4 border border-white border-opacity-10 w-[90%] max-w-[1000px] mx-auto md:my-auto
        shadow-xl shadow-balck relative min-h-fit" @click.stop><!-- bg-opacity-10 bg-slate-200 -->
        <!-- bg-slate-950 bg-opacity-50 -->
            <div class="bg-slate-900 inset-0 absolute z-[10]"></div>
            <div class="bg-[url('@/assets/bg.jpg')] bg-cover bg-center absolute inset-0 z-[11] opacity-10"></div> 
            <!-- 
            <div class=" bg-slate-900 bg-opacity-90 inset-0 absolute z-[10]"></div>
            -->
            <!-- <div class="bg-[url('@/assets/bg_glow.jpg')] bg-cover bg-center opacity-80 inset-0 absolute z-[10] "></div> -->
            <div v-if="userAccess === false" class=" z-[20]">
                <div class="flex flex-row gap-5">
                    <h2 class="pt-2 mb-2">Get Full Access</h2>
                    <div class="shrink-0 ml-auto"><button @click="hideAccess()" class=" mr-1"><img src="@/assets/set/close.svg" class="w-5 h-5"></button></div>
                </div>

                <div class="mb-4 text-lg items-center">
                        <div class="mb-2">
                        At the moment, Airpump is in Early Access.
                         <!-- -->
                         The full features of the platform is available to users with invitation code.
                        We are working to make the platform available to everyone.
                        <!-- However, you can still use most features of the Airpump platform without full access. -->
                        </div>
                    </div>



                <!-- <div class="flex flex-row gap-6 items-center mt-2">

                    <div class="md:w-fit bg-slate-950 rounded-xl p-5 bg-opacity-75 shadow-slate-950 shadow-sm border-slate-950 border border-opacity-10">
                        <div class="uppercase c0 text-sm">Your position</div>
                        <div class="flex flex-row gap-2">
                            <img src="@/assets/set/vip.svg" class="w-8 h-8 mt-1">
                            <h1 class="mb-2 c1">43943</h1>
                        </div>
                    </div>
                    <div class="mb-4 text-lg items-center">
                        <div class="mb-2">
                            We highly appreciate your interest in Airpump!
                            Project is currently in early stage and granting full access in queue.
                        </div>
                        <div class="text-sm">Please follow the announcements and roadmap when Airpump opens to public.</div>
                    </div>

                </div> -->
                <!-- <div class="mt-4 mb-4 text-lg">
                    Get instant access by one of the following options:
                </div> -->
            
                <div class="flex flex-col md:flex-row gap-5 mk:gap-8">
                    <div class="md:w-1/2 bg-slate-950 rounded-xl p-5 bg-opacity-75 shadow-slate-950 shadow-sm border-slate-950 border border-opacity-10">
                        <h3 class="mb-2">Get Invitation</h3>
                        <div class="flex flex-col gap-2">
                            <!-- <div class="mb-3">
                                <div class="mb-3">Join the community:</div>
                            </div> -->
                            <div class="mb-2">
                                Join Airpump social channels to check for the invitation code shared by the community.
                            </div>
                            <div class="flex flex-row gap-2">
                                <a href="https://t.me/airpumpfun" target="_blank" class="b4 bb w-fit">Follow <img src="@/assets/soc/c2/tg.svg" class="w-5 h-5"></a>
                                <a href="https://x.com/airpumpfun" target="_blank" class="b4 bb w-fit">Follow <img src="@/assets/soc/c2/x.svg" class="w-5 h-5"></a>
                            </div>
                            <div class="flex flex-row gap-2 items-center mt-2">
                                <img src="@/assets/set/book-w.svg" class="w-5 h-5 inline-block">
                                <router-link :to="{ name: 'docs_access' }" class="linkc">Read more about Early Access</router-link>
                                <span class="mr-2 c2">&rarr; </span>
                            </div>
                           
                        </div>
                    </div>

                    <div class="md:w-1/2 bg-slate-950 rounded-xl p-5 bg-opacity-75 shadow-slate-950 shadow-sm border-slate-950 border border-opacity-10">
                        <h3 class="mb-2">Have a Code?</h3>
                        <div class="mb-2">
                            Access the advanced features of the Airpump by entering the invitation code:
                        </div>
                        <div v-if="isLoadingAccess" class="flex flex-row gap-2 my-2">
                            <div class="spinner-32"></div>
                        </div>
                        <div v-else class="flex flex-row gap-2 my-2">
                            <input type="text" 
                                v-model="formCode" 
                                class="text w-[80%]" 
                                placeholder="Invitation code"
                                maxlength="30"
                                @input="formCode = $event.target.value.replace(/[^a-zA-Z0-9]/g, '')" />
                            <button class="b4 bb w-fit" @click="getAccess()" :disabled="isLoadingAccess">Done</button>
                        </div>
                        <div class="c2">
                           You can still use most features of the Airpump platform without full access.
                        </div>
                
                    </div>

                    <!-- <div class="md:w-1/3 bg-slate-950 rounded-xl p-5 bg-opacity-75 shadow-slate-950 shadow-sm border-slate-950 border border-opacity-10">
                        <h3 class="mb-2">KOL & Media</h3>
                        <div class="flex flex-col gap-2">
                            <div>Please get in touch, we'll be happy to provide you with access to early Airpump account.</div>
                            <router-link :to="{ name: 'docs' }" class="b4 bb w-fit mt-2"  @click.native="hideAccess()">Send a Message</router-link>
                        </div> 
                    </div>-->
                    
                </div>
                <div class="mt-4">

                    <div class="flex flex-row gap-2">
                        <div>KOL & Media representatives, please <router-link :to="{ name: 'affiliate' }" class="linkc"  @click.native="hideAccess()">get in touch</router-link>, we'll be happy to provide you with access to early Airpump account.</div>
                        <!-- <router-link :to="{ name: 'feedback' }" class="b4 bb w-fit mt-2"  @click.native="hideAccess()">Send a Message</router-link> -->
                    </div>
                    
                    <!-- The team is working to make the Airpump available to everyone. -->

                </div>
                <div class="flex flex-row gap-5 mt-5 justify-between">
                    <router-link :to="{ name: 'docs_access' }" class="b3 bc w-fit"  @click.native="hideAccess()">Learn more</router-link>
                    <button class="b3 ba w-fit" @click="hideAccess()">Close</button>
                </div>

            </div>
            <div v-else class=" z-[20]">
                <div class="flex flex-row gap-5">
                    <h2 class="pt-2 mb-2">Access Granted</h2>
                    <div class="shrink-0 ml-auto"><button @click="hideAccess()" class=" mr-1"><img src="@/assets/set/close.svg" class="w-5 h-5"></button></div>
                </div>

                <div class="mb-4 text-lg items-center">
                    <div class="mb-2">
                        You’ve been granted Early Access to Airpump &mdash; all platform features are now available to you.
                    </div>
                    <div class="mb-2">
                        Plus, you’ve received 3 invite codes to share with friends — that allows to give them Early Access too!
                        When they start using Airpump, you’ll earn SOL based on their activity.
                    </div>
                </div>
            
                <div class="flex flex-col md:flex-row gap-5 mk:gap-8">
                    <div class="md:w-1/2 bg-slate-950 rounded-xl p-5 bg-opacity-75 shadow-slate-950 shadow-sm border-slate-950 border border-opacity-10">
                        <h3 class="mb-2">Join Community</h3>
                        <div class="flex flex-col gap-2">
                    
                            <div class="mb-2">
                                Follow us on X and join Airpump Telegram channel:
                            </div>
                            <div class="flex flex-row gap-2">
                                <a href="https://t.me/airpumpfun" target="_blank" class="b4 bb w-fit">Follow <img src="@/assets/soc/c2/tg.svg" class="w-5 h-5"></a>
                                <a href="https://x.com/airpumpfun" target="_blank" class="b4 bb w-fit">Follow <img src="@/assets/soc/c2/x.svg" class="w-5 h-5"></a>
                            </div>
                           
                        </div>
                    </div>

                </div>
                <div class="mt-4">

                    <div class="flex flex-row gap-2">
                        <div>Do you have a media channel? <router-link :to="{ name: 'affiliate' }" class="linkc"  @click.native="hideAccess()">Apply for Affiliate</router-link> to get even more invitation and direct support from the team.</div>
                    </div>
                    
                    <!-- The team is working to make the Airpump available to everyone. -->

                </div>
                <div class="flex flex-row gap-5 mt-5 justify-between">
                    <router-link :to="{ name: 'docs_access' }" class="b3 bc w-fit"  @click.native="hideAccess()">Learn more</router-link>
                    <button class="b3 ba w-fit" @click="hideAccess()">Close</button>
                </div>

            </div>
        </div>

    </div>

</template>

<script>
import api from '@/api';
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            isLoadingAccess: false,
            formCode: '',
        }
    },
    computed: {
        ...mapState(['codeInvite']),
        ...mapState(['user', 'userAccess']),
    },
    created() {
        // Initialize formCode with stored codeInvite if available
        if (this.codeInvite) {
            this.formCode = this.codeInvite;
        }
    },
    methods: {
        
        ...mapActions(['showInformation']),
        ...mapActions(['getUserData']),

        hideAccess() {
            this.$emit('hideAccess');
        },
        validateCode(code) {
            // Check if code contains only letters and numbers and is max 30 chars
            return /^[a-zA-Z0-9]{1,30}$/.test(code);
        },
        async getAccess() {
            if (!this.formCode) {
                this.showInformation({
                    message: 'Please enter invitation code',
                    type: 'error',
                });
                return;
            }

            if (!this.validateCode(this.formCode)) {
                this.showInformation({
                    message: 'Code can only contain letters and numbers (max 30 symbols)',
                    type: 'error',
                });
                return;
            }

            this.isLoadingAccess = true;
            try {
                const response = await api.post('user/invite/getaccess', {
                    data: { code: this.formCode },
                });
                if (response.data.result === 'success') {
                    this.hideAccess();
                    this.showInformation({
                        message: 'Access granted',
                        type: 'success',
                    });
                    // this.userAccess = true;
                    await this.getUserData();
                } else {
                    // Handle error response with status other than success
                    throw new Error(response.data.message || 'Unknown error occurred');
                }
            } catch (error) {
                // Extract the error message from the response if available
                let errorMessage = 'Failed to get access';
                
                if (error.response && error.response.data) {
                    // API returned an error response
                    errorMessage = error.response.data.message || errorMessage;
                } else if (error.message) {
                    // Use the error message from the thrown error
                    errorMessage = error.message;
                }
                
                this.showInformation({
                    message: errorMessage,
                    type: 'error',
                });
                console.error('Error getting access:', error);
            } finally {
                this.isLoadingAccess = false;
            }
        },
        // startTour() {
        //     this.$emit('startTour');
        // }
    }
}
</script>