<template>

    <!-- max-md:bottom-24 md:bottom-5 -->
    <div @click="hideInformation" v-if="information" 
            class="z-[999] max-w-[1200px] md:w-[80%] p-2 md:p-4 md:px-3 mx-auto fixed border max-md:text-sm
            max-md:bottom-20 md:bottom-12 left-3 right-3 text-center shadow-2xl rounded-[10px] md:rounded-[18px] bg-opacity-80 backdrop-blur-sm"
            :class="{
            'text-[#C5DD76] border-slate-700 bg-slate-900': information?.type === 'success',
            'text-red-200 border-red-800 bg-red-950': information?.type === 'error',
            'text-yellow-200 border-yellow-800 bg-yellow-950': information?.type === 'warning',
            'text-blue-200 border-blue-800 bg-blue-950': information?.type === 'info',
            'text-slate-200 border-slate-500 bg-slate-800 ': information?.type === 'load',
            'text-slate-200 bg-slate-800 border-slate-700': !information?.type
            }">
            <!-- border-[#C5DD76] border-opacity-30 -->
            <div class="spinner" v-if="information?.type === 'load'"></div>
            {{ information?.message }}
    </div>

</template>

<script>

import { mapState, mapActions } from 'vuex';

export default {
    props: {
        information: {
            type: Object,
            required: false,
            default: null
        }
    },
    // data() {
    //     return {
    //         showNotify: true
    //     }
    // },
    methods: {
        ...mapActions(['hideInformation']),
    }
}

</script>