import { handleError } from '../../utils/errors';

const state = {
    isConnected: false,
    isSigning: false,
    address: null,
    isInstalled: false,
    chainId: null,
    chainName: false,
};

const mutations = {
    SET_CONNECTED(state, isConnected) {
        state.isConnected = isConnected;
    },
    SET_SIGNING(state, isSigning) {
        state.isSigning = isSigning;
    },
    SET_WALLET_ADDR(state, address) {
        state.address = address;
        this.commit('wallets/SET_WALLET_ADDR', address, { root: true });
    },
    SET_INSTALLED(state, isInstalled) {
        // alert('SET_INSTALLED metamask isInstalled='+isInstalled);
        state.isInstalled = isInstalled;
    },
    SET_CHAIN_ID(state, chainId) {
        state.chainId = chainId;
        // alert('SET_CHAIN_ID chainId='+chainId);
        console.log('Metamask changed network to chainId='+chainId);
        if(chainId=='0x1') {
            state.chainName = 'Ethereum';
        } else if(chainId=='0x2105') {
            state.chainName = 'Base';
        } else if(chainId=='0xe708') {
            state.chainName = 'Linea';
        } else if(chainId=='0x38') {
            state.chainName = 'BNB Chain';
        } else {
            state.chainName = 'Unknown';
        }
    },
};

const actions = {
    async init({ commit, dispatch }) {
        const isInstalled = await dispatch('checkIfInstalled');
        commit('SET_INSTALLED', isInstalled);
        if (isInstalled) {
            const provider = await dispatch('getProvider');
            if (provider) {
                provider.on('accountsChanged', (accounts) => {
                    console.log(`MetaMask Event - accounts changed to ${accounts[0]}`);
                    if (accounts.length > 0) {
                        commit('SET_CONNECTED', true);
                        commit('SET_WALLET_ADDR', accounts[0]);
                    } else {
                        commit('SET_CONNECTED', false);
                        commit('SET_WALLET_ADDR', null);
                    }
                });
                provider.on('chainChanged', (chainId) => {
                    console.log(`MetaMask Event - chain changed to ${chainId}`);
                    commit('SET_CHAIN_ID', chainId);
                });
                provider.on('disconnect', () => {
                    console.log(`MetaMask Event - disconnect`);
                    commit('SET_CONNECTED', false);
                    commit('SET_WALLET_ADDR', null);
                });
            }
        }
    },

    async connect({ commit, dispatch }, { justAdd = false, justConnect = false }) {
        // alert('metamask connect, justAdd = '+justAdd);
        const provider = await dispatch('getProvider');
        if (!provider) {
            throw new Error('MetaMask is not installed');
        }
        try {
            const accounts = await provider.request({ method: 'eth_requestAccounts' });
            const chainId = await provider.request({ method: 'eth_chainId' }); // 0x1 - eth, 0x2105 - base

            // alert('chainId='+chainId);
            
            if (accounts.length > 0) { // !! TODO: !!! IMPORTATN!! WHAT WALLET CONNECTED? 
                //  how to deal with other connected wallets here?

                if(justConnect) {
                    dispatch('showInformation', { 
                        message: 'MetaMask wallet connected successfully', 
                        type: 'success' 
                    }, { root: true });
                
                } else {

                    const walletType = 'evm';
                    const walletNetwork = 1; // 'ethereum';
                    const walletProvider = 'metamask';

                    let methodInit = 'authWalletSignInit';
                    let methodVerify = 'authWalletSignVerify';
                    if(justAdd) {
                        methodInit = 'authWalletSetInit';
                        methodVerify = 'authWalletSetVerify';
                    }

                    const address = accounts[0];
                    commit('SET_WALLET_ADDR', address);
                    commit('SET_CONNECTED', true);
                    commit('SET_SIGNING', true);
                    commit('SET_CHAIN_ID', chainId);

                    // Get the message to sign from the backend
                    const message = await dispatch(`${methodInit}`, { // authWalletSignInit or authWalletSetInit
                        walletAddress: address,
                        walletType,
                        walletNetwork,
                        walletProvider
                    }, { root: true });

                    // alert('received metamask message from backend to be signed in the wallet ='+message);
        
                    const signedMessage = await dispatch('signMessage', message);
    
                    // Authenticate with backend
                    await dispatch(`${methodVerify}`, { // authWalletSignVerify or authWalletSetVerify
                        walletAddress: address,
                        signedMessage,
                        walletType,
                        walletNetwork,
                        walletProvider
                    }, { root: true });

                    commit('SET_SIGNING', false);

                    dispatch('showInformation', { 
                        message: 'MetaMask wallet connected successfully', 
                        type: 'success' 
                    }, { root: true });

                }

            }
        } catch (err) {
            commit('SET_SIGNING', false);
            const errorMessage = handleError(err, '[STORE/metamask/connect]');
            throw new Error(errorMessage);
            // console.error('MetaMask connection error:', err);
            // alert('connect in metamask.js connection error: '+err);
            // dispatch('showInformation', { 
            //     message: `Failed to connect MetaMask wallet: ${err.message}`, 
            //     type: 'error' 
            // }, { root: true });
            // throw err;
        }
    },

    async disconnect({ commit, dispatch }) {
        commit('SET_CONNECTED', false);
        commit('SET_WALLET_ADDR', null);
        dispatch('showInformation', { 
            message: 'MetaMask wallet disconnected', 
            type: 'success' 
        }, { root: true });
    },

    // async signMessage({ dispatch }, message) {
    //     const provider = await dispatch('getProvider');
    //     if (!provider || !state.isConnected) {
    //         throw new Error('MetaMask wallet is not connected');
    //     }
    //     const signedMessage = await provider.request({
    //         method: 'personal_sign',
    //         params: [message, state.address],
    //     });
    //     alert('metamask signedMessage='+signedMessage);
    //     console.log('metamask signedMessage='+JSON.stringify(signedMessage));
    //     return signedMessage;
    // },
    async signMessage({ dispatch }, message) {
        // alert('metamask signMessage, message='+message);
        const provider = await dispatch('getProvider');
        if (!provider || !state.isConnected) {
            throw new Error('MetaMask wallet is not connected');
        }
        // Remove any hashing of the message here
        const signedMessage = await provider.request({
            method: 'personal_sign',
            params: [message, state.address],
        });
        // alert('metamask signedMessage='+signedMessage);
        console.log('metamask signedMessage='+JSON.stringify(signedMessage));
        return signedMessage;
    },

    async checkIfInstalled() {
        return new Promise((resolve) => {
            if (typeof window !== 'undefined') {
                resolve(typeof window.ethereum !== 'undefined');
            } else {
                resolve(false);
            }
        });
    },

    async getProvider() {
        return new Promise((resolve) => {
            if (typeof window !== 'undefined' && typeof window.ethereum !== 'undefined') {
                resolve(window.ethereum);
            } else {
                resolve(null);
            }
        });
    },

    removeListeners({ dispatch }) {
        dispatch('getProvider').then(provider => {
            if (provider) {
                provider.removeAllListeners('accountsChanged');
                provider.removeAllListeners('chainChanged');
                provider.removeAllListeners('disconnect');
            }
        });
    },

    // Add this action to switch to Base chain if needed
    async switchChain({ dispatch }, toChain ) {
        const provider = await dispatch('getProvider');
        if (!provider) {
            throw new Error('MetaMask is not installed');
        }
        try {
            if(toChain=='base') {
                alert('switchChain to base');
                await provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x2105' }], // Chain ID for Base in hex
                });
            } else if(toChain=='ethereum') {
                alert('switchChain to eth');
                await provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x1' }], // Chain ID for Base in hex
                });
            }
        } catch (error) {
            if (error.code === 4902) { // for base
                // Chain not added, let's add it
                if(toChain=='base') {
                await provider.request({
                    method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: '0x2105',
                            chainName: 'Base',
                            nativeCurrency: {
                                name: 'ETH',
                                symbol: 'ETH',
                                decimals: 18
                            },
                            rpcUrls: ['https://mainnet.base.org'],
                            blockExplorerUrls: ['https://basescan.org']
                        }],
                    });
                }  
                // else if(toChain=='linea') {
                //     await provider.request({
                //         method: 'wallet_addEthereumChain',
                //         params: [{
                //             chainId: '0xe708',
                //             chainName: 'Linea',
                //             nativeCurrency: {
                //                 name: 'ETH',
                //                 symbol: 'ETH',
                //                 decimals: 18
                //             },
                //             rpcUrls: ['https://linea-mainnet.rpc.linea.build'],
                //             blockExplorerUrls: ['https://lineascan.build']
                //         }],
                //     });
                // }
            }
            throw error;
        }
    },
};

export default { namespaced: true, state, mutations, actions };