import api from '@/api';
import { root } from 'postcss';

const state = {
    noWallets: null,
    playWallets: [],
    selectedWallet: null,
    isWalletsLoading: false,
    isBalanceRefreshing: false,
    error: null,
    editingWallet: null
};

const mutations = {
    setPlayWallets(state, wallets) {
        state.playWallets = wallets;
    },
    setSelectedWallet(state, wallet) {
        state.selectedWallet = wallet;
    },
    setLoading(state, status) {
        state.isWalletsLoading = status;
    },
    setError(state, error) {
        state.error = error;
    },
    setRefreshing(state, status) {
        state.isBalanceRefreshing = status;
    },
    setEditingWallet(state, wallet) {
        state.editingWallet = wallet;
    },
    setNoWallets(state, status) {
        state.noWallets = status;
    },
    clearPlayWallet(state) {
        state.noWallets = null;
        state.playWallets = [];
        state.selectedWallet = null;
        state.isWalletsLoading = false;
        state.isBalanceRefreshing = false;
        state.error = null;
        state.editingWallet = null;
    }
};

const actions = {

    async fetchWallets({ commit, state }) {
        console.log('{playwallet/fetchWallets}');
        // alert('fetchWallets');
        commit('setLoading', true);
        try {
            const response = await api.post('user/wallet/list', { 
                data: {
                    page: 1,
                    limit: 50,
                    status: 'all',
                    sort: 'wallet_created',
                    order: 'desc'
                }
            });
            commit('setPlayWallets', response.data.wallets);
            if(response.data.wallets.length > 0) {
                if (!state.selectedWallet) {
                     // Set the first wallet initially if none selected
                    commit('setSelectedWallet', response.data.wallets[0]);
                } else {
                    // Update selected wallet data object as well
                    const selectedWalletId = state.selectedWallet.wallet_id;
                    const updatedWallet = response.data.wallets.find(
                        wallet => wallet.wallet_id === selectedWalletId
                    );
                    if (updatedWallet) {
                        commit('setSelectedWallet', updatedWallet);
                    }
                }
                commit('setNoWallets', false);
            } else {
                commit('setNoWallets', true);
            }
            
        } catch (error) {
            commit('setError', error.message);
        } finally {
            commit('setLoading', false);
        }
    },

    async createWallet({ commit, dispatch }) {
        commit('setLoading', true);
        try {
            const response = await api.post('user/wallet/create', { 
                data: {}
            });
            
            if(response.data.status === 'success') {
                await dispatch('fetchWallets');
                commit('setSelectedWallet', response.data.wallet);
                dispatch('showInformation', { 
                    message: 'New wallet created successfully', 
                    type: 'success' 
                }, { root: true });
                dispatch('modals/setWalletNew', { newWallet: true }, { root: true });
                return response.data.wallet;
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('setError', error.message);
            dispatch('showInformation', { 
                message: 'Error creating wallet: '+error.message, 
                type: 'error' 
            }, { root: true });
            throw error;
        } finally {
            commit('setLoading', false);
        }
    },

    async renameWallet({ commit, dispatch, state }, { wallet_id, wallet_name }) {
        try {
            const response = await api.post('user/wallet/rename', { 
                data: { 
                    wallet_id,
                    wallet_name
                }
            });
            if (state.selectedWallet && state.selectedWallet.wallet_id === wallet_id) {
                commit('setSelectedWallet', {
                    ...state.selectedWallet,
                    wallet_name: wallet_name
                });
            }
            await dispatch('fetchWallets');
            return response.data;
        } catch (error) {
            commit('setError', error.message);
            throw error;
        }
    },

    async checkBalance({ commit, dispatch, state }) {
        if (!state.selectedWallet?.wallet_id) return;
        
        commit('setRefreshing', true);
        try {
            const response = await api.post('user/wallet/balance', {
                data: { wallet_id: state.selectedWallet.wallet_id }
            });
            await dispatch('fetchWallets');
            return response.data;
        } catch (error) {
            commit('setError', error.message);
            throw error;
        } finally {
            commit('setRefreshing', false);
        }
    }
};

const getters = {
    formattedWalletAddress: state => {
        if (!state.selectedWallet) return '';
        return state.selectedWallet.wallet_address;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};