<template>

    <div @click="hideModal()" class="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-45 z-[800] flex flex-col h-full py-8 overflow-y-auto">
    <!-- <div @click="hideModal()" class="fixed inset-0 bg-slate-900 backdrop-blur-sm bg-opacity-75 z-[800] flex flex-col h-full py-8 overflow-y-auto"> -->

        <div @click.stop class="bg-slate-800 md:p-6 p-4 shadow-xl shadow-slate-950
        rounded-sm border border-white border-opacity-10
        
         min-w-[320px] w-[80%] max-w-[500px] mx-auto my-auto ">
            <div class="flex flex-row justify-between">
                <h2 class="font-bold mb-3">Connect Wallet</h2>
                <div @click="hideModal()" class="p2 cursor-pointer" ><img src="@/assets/set/close.svg" class="w-5 h-5"></div>
                <!-- border-slate-400 rounded-full -->
            </div>

            <div v-if="isLoading" class="flex flex-col justify-center gap-3 border border-slate-600 p-5 rounded-xl mb-4 text-center min-h-[250px]">
                <div class="spinner-32 mx-auto"></div>
                <h3 class="font-bold">Loading...</h3>
            </div>
            <div v-else>
                    
                <div v-if="isPhantomSigning || isSolflareSigning || isMetamaskSigning" class="mb-3 text-slate-300">Signing...</div>

                <div  class="flex flex-col gap-3">

                    <div class="mb-2">Connect your wallet
                        <span v-if="isMobile">application</span>
                        <span v-else>browser extension</span>
                        to continue:
                    </div>
                    <!-- <h3 class="mb-2 font-bold">Connect Wallet</h3> -->
                    
                    <!-- <h4 class="mb-1 c3">Ethereum</h4> -->
                    <button @click="connectMetamask()" :disabled="isConnecting" class="b2 ba">
                        <div class="flex flex-row gap-2 justify-between w-full">
                            <span v-if="isConnecting && walletType === 'metamask'">Connecting...</span>
                            <span v-else-if="isMetamaskSigning">Awaiting Signature...</span>
                            <!-- <span v-else>{{ isPhantomInstalled ? 'Connect Phantom' : 'Install Phantom' }}</span> -->
                            <span v-else class="font-bold">Metamask</span>
                            <span class="c2 text-sm">
                                <!-- <img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block">  -->
                                <span v-if="!isMetamaskInstalled" class=" flex flex-row items-center gap-2 text-slate-400">Not detected</span>
                                <span v-else class=" flex flex-row items-center gap-2 font-bold c1"><img src="@/assets/set/check.svg" class="w-5 h-5 inline-block"> Detected</span>
                            </span>
                        </div>
                    </button>
                    
                <!-- </div>
                <div  class="border-b border-slate-600 pb-5 p-5 flex flex-col gap-3">

                    <h4 class="mb-1 c3">Solana</h4> -->
                    <button @click="connectPhantom()" :disabled="isConnecting" class="b2 ba">
                        <div class="flex flex-row gap-2 justify-between w-full">
                            <span v-if="isConnecting && walletType === 'phantom'">Connecting...</span>
                            <span v-else-if="isSigning && walletType === 'phantom'">Awaiting Signature...</span>
                            <!-- <span v-else>{{ isPhantomInstalled ? 'Connect Phantom' : 'Install Phantom' }}</span> -->
                            <span v-else class="font-bold">Phantom</span>
                            <span class="c2 text-sm">
                                <!-- <img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block">  -->
                                <span v-if="!isPhantomInstalled" class=" flex flex-row items-center gap-2 text-slate-400">Not detected</span>
                                <span v-else class=" flex flex-row items-center gap-2 font-bold c1"><img src="@/assets/set/check.svg" class="w-5 h-5 inline-block"> Detected</span>
                            </span>
                        </div>
                    </button>
                    <!-- <div v-if="!isPhantomInstalled" class="mb-2 text-slate-300"><img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block"> Phantom wallet not detected</div> -->

                    <button @click="connectSolflare()" :disabled="isConnecting" class="b2 ba">
                        <div class="flex flex-row gap-2 justify-between w-full">
                            <span v-if="isConnecting && walletType === 'solflare'">Connecting...</span>
                            <span v-else-if="isSigning && walletType === 'solflare'">Awaiting Signature...</span>
                            <!-- <span v-else>{{ isSolflareInstalled ? 'Connect Solflare' : 'Install Solflare' }}</span> -->
                            <span v-else class="font-bold">Solflare</span>
                            <span class="c2 text-sm">
                                <span v-if="!isSolflareInstalled" class=" flex flex-row items-center gap-2 text-slate-400">Not detected</span>
                                <span v-else class=" flex flex-row items-center gap-2 font-bold c1"><img src="@/assets/set/check.svg" class="w-5 h-5 inline-block"> Detected</span>
                            </span>
                        </div>
                    </button>
                    
                    
                    <!-- <button @click="connectAdapter()" :disabled="isConnecting" class="b3 bc">
                        <span v-if="isConnecting && walletType === 'adapter'">Connecting...</span>
                        <span v-else-if="isSigning && walletType === 'adapter'">Awaiting Signature...</span>
                        <span v-else class="font-bold">Mobile Wallet</span>
                    </button>
                    <div v-if="!isAdapterInstalled" class="mb-2 text-slate-300"><img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block"> Mobile wallet not detected</div> -->

                </div>

            </div>

        </div>

    </div> 

</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/api';

export default {
    name: 'Airpump - Sign In',
    data() {
        return {

            DEBUG: false,
            isLoading: false,
            signStep: 1,
            sendIn: 0,
            sendInNext: 30, // 30 sec
            resendTimer: null,
            isSubmit: false,
            signEmail: true,
            signWallet: false,
            signTelegram: false,
            telegramAuthLink: null,
            sessionCode: null, // for telegram to identify session
            isPhantomWallet: false,
            isSolflareWallet: false,

        }
    },
    computed: {

        ...mapState('wallets', ['isConnecting', 'isConnected', 'walletNetwork', 'walletType', 'walletData', 'walletAddr', 'isSigning']),
        ...mapState('metamask', {isMetamaskInstalled: 'isInstalled', isMetamaskSigning: 'isSigning', metamaskChainName: 'chainName' }),
        ...mapState('phantom', {isPhantomInstalled: 'isInstalled', isPhantomSigning: 'isSigning' }),
        ...mapState('solflare', {isSolflareInstalled: 'isInstalled', isSolflareSigning: 'isSigning' }),
        ...mapState(['isMobile']),

    },
    mounted() {
           // _${this.codeInvite || 'no'}_${this.utmSource || 'direct'}_${this.utmCampaign || 'no'}_${this.utmMedium || 'no'}`;
    },
    created() {
        this.checkWalletBrowser();
    },
    methods: {

        ...mapActions(['showInformation', 'triggerWalletsRefresh']),

        //* Wallet Auth *//

        ...mapActions('wallets', ['connectWallet', 'disconnectWallet', 'signMessage']),
        
        async connectMetamask() {
            if (!this.isMetamaskInstalled) {
                this.openWalletInstallPage('metamask');
            } else {
                try {
                    await this.connectWallet({ walletType: 'metamask', justAdd: true, deeplink: false });
                    await this.triggerWalletsRefresh();
                    this.hideModal();
                } catch (error) {
                    this.showInformation({
                        message: 'Failed to initiate wallet connection. Please try again.',
                        type: 'error'
                    });
                    this.connectError=error;
                    // The error notification is already handled in the store action
                    // Update the signing status here if needed
                    // this.$store.commit('metamask/SET_SIGNING', false);
                }
            }
        },

        async connectPhantom() {
            if (!this.isMobile && !this.isPhantomInstalled) { // not show install page on mobile
                this.openWalletInstallPage('phantom');
            } else {
                const useDeeplink = this.isMobile && !this.isPhantomWallet;
                await this.connectWallet({
                    walletType: 'phantom',
                    mode: 'connect',
                    justAdd: true,
                    deeplink: useDeeplink
                });
                await this.triggerWalletsRefresh();
                this.hideModal();
            }
        },
        
        async connectSolflare() {
            if (!this.isMobile && !this.isSolflareInstalled) { // not show install page on mobile
                this.openWalletInstallPage('solflare');
            } else {
                const useDeeplink = this.isMobile && !this.isSolflareApp();
                await this.connectWallet({
                    walletType: 'solflare',
                    mode: 'connect', 
                    justAdd: true,
                    deeplink: useDeeplink
                });
                await this.triggerWalletsRefresh();
                this.hideModal();
            }
        },

        // async connectAdapter() {
        //     // if (!this.isadapterInstalled) {
        //         // this.openWalletInstallPage('adapter');
        //         // alert('No mobile wallet detected');
        //     // } else {
        //         await this.connectWallet('adapter');
        //     // }
        // },

        openWalletInstallPage(wallet) {
            let url;
            if (this.isMobile) {
                if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    switch (wallet) {
                        case 'phantom':
                            url = 'https://apps.apple.com/app/phantom-solana-wallet/id1598432977';
                            break;
                        case 'solflare':
                            url = 'https://apps.apple.com/app/solflare-wallet/id1580902717';
                            break;
                        case 'metamask':
                            url = 'https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202';
                            break;
                    }
                } else if (/Android/i.test(navigator.userAgent)) {
                    switch (wallet) {
                        case 'phantom':
                            url = 'https://play.google.com/store/apps/details?id=app.phantom';
                            break;
                        case 'solflare':
                            url = 'https://play.google.com/store/apps/details?id=com.solflare.mobile';
                            break;
                        case 'metamask':
                            url = 'https://play.google.com/store/apps/details?id=io.metamask';
                            break;
                    }
                }
            } else {
                switch (wallet) {
                    case 'phantom':
                        url = 'https://phantom.app/';
                        break;
                    case 'solflare':
                        url = 'https://solflare.com/';
                        break;
                    case 'metamask':
                        url = 'https://metamask.io/download/';
                        break;
                }
            }
            if (url) {
                window.open(url, '_blank');
            }
        },
        // isMobile() {
        //     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        // },

        //* Modal Control *//

        showConnect() {
            this.$emit('showConnect');
        },
        hideModal() {
            this.$emit('hideModal');
        },

        checkWalletBrowser() {
            const isInPhantom = this.isPhantomApp();
            const isInSolflare = this.isSolflareApp();
            
            if (isInPhantom || isInSolflare) {
                this.$store.commit('SET_MOBILE', false);
                this.isPhantomWallet = isInPhantom;
                this.isSolflareWallet = isInSolflare;
            }
        },
        
        isPhantomApp() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /Phantom\/(?:ios|android)/i.test(userAgent);
        },

        isSolflareApp() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /Solflare-Mobile/i.test(userAgent);
        },

    },
    beforeUnmount() {
        if (this.resendTimer) {
            clearInterval(this.resendTimer);
        }
    }
}
</script>
