<template>
    <div class="flex-1 overflow-y-auto p-3 scrollbar-hide">
        <!-- <h3 class="c2">Private Key</h3> -->
        <h3 class="c2">Export Wallet</h3>

        <div class="bg-slate-900 rounded-xl p-4 flex flex-col gap-3 mt-3">

            <div v-if="showPrivateKey" class="flex flex-col gap-4">

                <h5 class="c3">Archived Wallet</h5>

                <div class="bg-slate-800 rounded-lg p-2 px-3 font-mono text-sm c1 flex items-center relative">
                    <div class="overflow-hidden relative flex-1 pr-2">
                        <div class="truncate" ref="addressText">{{ selectedWallet?.wallet_address }}</div>
                        <div v-if="isTextTruncated" class="absolute right-0 top-0 h-full w-12 bg-gradient-to-r from-transparent via-slate-800 to-slate-800"></div>
                    </div>
                    <button @click="copyAddress()" class="flex-shrink-0">
                        <img src="@/assets/set/copy-g.svg" class="w-4 h-4">
                    </button>
                </div>

                <div>
                    The wallet was placed to archive and is no longer
                     available
                    for operations on the Airpump platform.
                    You can export the private key and use this wallet in your browser extension or mobile wallet.
                </div>

                <h5 class="c3">Private Key</h5>

                <div class="bg-slate-800 rounded-lg p-2 px-3 font-mono text-sm c1 flex items-center relative">
                    <div class="overflow-hidden relative flex-1 pr-2">
                        <div class="break-all" ref="addressText">
                            <span :class="{'blur-md': !isRevealed}" class="transition-all duration-300">
                                <span v-if="displayKey === null" class="spinner-20"></span>
                                <span v-else>{{ displayKey }}</span>
                            </span>
                        </div>
                    </div>
                    <button @click="copyPrivateKey()" class="flex-shrink-0" v-if="isRevealed">
                        <img src="@/assets/set/copy-g.svg" class="w-4 h-4">
                    </button>
                </div>

                <div class="c8  flex flex-col gap-1">
                    
                    <!-- Once you export the private key  -->
                    <span class="uppercase font-bold">
                        <img src="@/assets/set/warn-r.svg" class="w-5 h-5 inline-block"> 
                        Private Key provides full access to all the assets and funds stored on the wallet.
                        Please keep it safe and do not share it with anyone!
                    </span>
            
                </div>

                <div class="relative" v-if="!isRevealed">
                    <button 
                        class="b3 bp relative w-full" 
                        @mousedown="startHolding" 
                        @mouseup="stopHolding"
                        @mouseleave="stopHolding"
                        @touchstart.prevent="startHolding"
                        @touchend.prevent="stopHolding"
                        @touchcancel.prevent="stopHolding"
                    >
                        <div class="flex items-center justify-center gap-2" :class="{'opacity-0': isHolding}">
                            <img src="@/assets/set/lock-g.svg" class="w-4 h-4">
                            <span>Hold to reveal private key</span>
                        </div>
                        
                        <!-- Progress Circle with centered lock -->
                        <div v-if="isHolding" class="absolute inset-0 flex items-center justify-center">
                            <div class="relative w-6 h-6">
                                <svg class="w-6 h-6 absolute" viewBox="0 0 24 24">
                                    <circle 
                                        cx="12" 
                                        cy="12" 
                                        r="10" 
                                        fill="none" 
                                        stroke="#4F46E5" 
                                        stroke-width="2"
                                        stroke-dasharray="62.83"
                                        :stroke-dashoffset="progressOffset"
                                        class="transform -rotate-90 origin-center transition-all duration-75"
                                    />
                                </svg>
                                <img src="@/assets/set/lock-g.svg" class="w-4 h-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            </div>
                        </div>
                    </button>
                </div>

                <div v-else class="text-lg text-orange-200 text-center bg-orange-800/20 rounded-lg p-2">
                    Key will be hidden in {{ timeLeft }} seconds
                </div>

                <button class="b2 be" @click="toggleView('home')">Cancel</button>

            </div>
            <div v-else-if="isArchiving">
                <div class="flex flex-col gap-4 border-b border-slate-700 pb-3">
                    <h5 class="c3">Wallet Archivation</h5>
                    <div class="spinner-32 mx-auto my-5"></div>
                    <div class="text-lg">
                        Please wait while your wallet is archived...
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-4 border-b border-slate-700 pb-3" v-if="!showPrivateKey">
                <h5 class="c3">Wallet Archivation Notice</h5>
                <div class="text-lg">
                    You are requesting private key for the wallet address:
                </div>

                <div class="bg-slate-800 rounded-lg p-2 px-3 font-mono text-sm c1 flex items-center relative">
                    <div class="overflow-hidden relative flex-1 pr-2">
                        <div class="truncate" ref="addressText">{{ selectedWallet?.wallet_address }}</div>
                        <div v-if="isTextTruncated" class="absolute right-0 top-0 h-full w-12 bg-gradient-to-r from-transparent via-slate-800 to-slate-800"></div>
                    </div>
                    <button @click="copyAddress()" class="flex-shrink-0">
                        <img src="@/assets/set/copy-g.svg" class="w-4 h-4">
                    </button>
                </div>

                <div class="c8  flex flex-col gap-1">
                 
                    <!-- Once you export the private key  -->
                    <span class="uppercase font-bold">
                        <img src="@/assets/set/warn-r.svg" class="w-5 h-5 inline-block"> 
                        This wallet will no longer be available
                        for operations on the Airpump platform.
                    </span>
             
                    <div class="c8 text-sm">
                        Airpump platform reserves SOL to sign scheduled on-chain transactions for orders and coin launches. 
                        Once you export the private key, 
                        we can no longer have a guarantee that the reserved SOL won’t be used outside the intended operations.
                    </div>
                </div>
                <div class="text-sm">
                    Your wallet will be archived on the Airpump platforms
                    and you will be able to import this key in your own wallet.
                </div>

                <button class="b2 be " @click="doArchive()"><span class="c8">Archive The Wallet</span></button>

            </div>
            
            <div class="mb-2 text-slate-300 flex flex-row gap-2  items-start">
                <img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block"> 
                <div class="text-sm uppercase font-bold">IMPORTANT! 
                    Private key grants access to your wallet.
                    Do not share this key with anyone!
                    Remember — Airpump team will never approach you and/or request your private key.

                    </div>
            </div>   
        </div>
    </div>

    <!-- Fixed Footer -->
    <div class="bg-slate-900 px-4 py-3 flex justify-between border-t border-slate-700 rounded-b-lg">

        <button class="b3 ba ml-auto" @click="toggleView('home')">Done</button>
    </div>
</template>

<script>
import api from '@/api';

import { mapState, mapActions } from 'vuex';
import QrcodeVue from 'qrcode.vue';

export default {
    components: {
        QrcodeVue
    },
    data() {
        return {
            showPrivateKey: false,
            isHolding: false,
            holdTimer: null,
            hideTimer: null,
            holdProgress: 0,
            progressInterval: null,
            privateKey: null,
            isRevealed: false,
            timeLeft: 15,
            countdownInterval: null,
            isArchiving: false,
            randomKey: '4jh1k24h1k24h1k24h1k4j2h4k12h4k12h4k1j2h4k1j2h4k12h4k12h4k1j2h4'
        }
    },
    computed: {
        ...mapState('playwallet', ['selectedWallet']),
        progressOffset() {
            // 62.83 is approximately 2πr where r=10 (circle radius)
            return 62.83 * (1 - this.holdProgress)
        },
        displayKey() {
            return this.isRevealed ? this.privateKey : this.randomKey
        }
    },
    async created() {
        await this.fetchWallets();
        if(this.selectedWallet.wallet_status === 2) {
            this.showPrivateKey = true;
        }
    },
    methods: {
        ...mapActions('playwallet', ['fetchWallets', 'isFetchingWallets']),
        ...mapActions(['showInformation']),
        toggleView(view) {
            this.$emit('toggleView', view);
        },
        copyAddress() {
            navigator.clipboard.writeText(this.selectedWallet?.wallet_address);
        },
        checkTextTruncation() {
            const element = this.$refs.addressText;
            if (element) {
                this.isTextTruncated = element.scrollWidth > element.clientWidth;
            }
        },
        copyPrivateKey() {
            if (this.privateKey) {
                navigator.clipboard.writeText(this.privateKey);
            }
        },
        startCountdown() {
            this.timeLeft = 15;
            this.countdownInterval = setInterval(() => {
                this.timeLeft--;
                if (this.timeLeft <= 0) {
                    this.hideKey();
                }
            }, 1000);
        },
        
        hideKey() {
            clearInterval(this.countdownInterval);
            this.isRevealed = false;
            // Generate new random key
            this.randomKey = Array(64).fill()
                .map(() => Math.random().toString(36)[2])
                .join('');
        },

        async doArchive() {
            this.isArchiving = true;
            try {
                const response = await api.post(`user/wallet/archive`, {
                    data: { wallet_id: this.selectedWallet.wallet_id }
                });
                if (response.data.status === 'success') {
                    this.showInformation({
                        message: 'Wallet archived successfully, you can now export the private key.',
                        type: 'success'
                    });
                    this.showPrivateKey=true;
                    this.fetchWallets();
                    
                } else {
                    throw new Error(response.data.message);
                }
            } catch (error) {
                this.showInformation({
                    message: error.response?.data?.message || error.message || 'Archivation failed',
                    type: 'error'
                });
            } finally {
                this.isArchiving = false;
            }
        },

        async startHolding() {

            if (this.isHolding) return;
            
            this.isHolding = true;
            this.holdProgress = 0;
            
            this.progressInterval = setInterval(() => {
                this.holdProgress = Math.min(1, this.holdProgress + 0.0167);
            }, 50);

            this.holdTimer = setTimeout(async () => {
                if (!this.isHolding) return;

                try {
                    const response = await api.post(`user/wallet/export`, {
                        data: { wallet_id: this.selectedWallet.wallet_id }
                    });
                    this.privateKey = response.data.wallet.private_key;

                } catch (error) {
                    console.error('Failed to fetch private key:', error)
                }

                this.showPrivateKey = true;
                this.isRevealed = true;
                this.startCountdown();
                // alert('holding finished')
                this.isHolding = false;

                if (this.progressInterval) {
                    clearInterval(this.progressInterval);
                    this.progressInterval = null;
                }
            }, 3000);
        },
        stopHolding() {
            if (this.isRevealed) return; // Don't stop if already revealed
            
            this.isHolding = false;
            
            if (this.holdTimer) {
                clearTimeout(this.holdTimer);
                this.holdTimer = null;
            }
            
            if (this.progressInterval) {
                clearInterval(this.progressInterval);
                this.progressInterval = null;
            }
            
            this.holdProgress = 0;
        }
    },
    mounted() {
        this.checkTextTruncation();
        window.addEventListener('resize', this.checkTextTruncation);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkTextTruncation);
    },
    watch: {
        'selectedWallet.wallet_address'() {
            this.$nextTick(this.checkTextTruncation);
        }
    },
    beforeDestroy() {
        if (this.holdTimer) {
            clearTimeout(this.holdTimer);
            this.holdTimer = null;
        }
        if (this.hideTimer) {
            clearTimeout(this.hideTimer);
            this.hideTimer = null;
        }
        if (this.progressInterval) {
            clearInterval(this.progressInterval);
            this.progressInterval = null;
        }
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
            this.countdownInterval = null;
        }
        this.privateKey = null;
    }
}
</script>

<style scoped>
.blur-md {
    filter: blur(8px);
}

.break-all {
    word-break: break-all;
}

circle {
    transition: stroke-dashoffset 75ms linear;
}

.opacity-0 {
    transition: opacity 0.2s;
}
</style>