<template>

    <div @click="hideModal()" class="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-45 z-[800] flex flex-col h-full py-8 overflow-y-auto">
    <!-- <div @click="hideModal()" class="fixed inset-0 bg-slate-900 backdrop-blur-sm bg-opacity-75 z-[800] flex flex-col h-full py-8 overflow-y-auto"> -->

        <div @click.stop class="bg-slate-800 md:p-6 p-4 shadow-xl shadow-slate-950
        rounded-sm border border-white border-opacity-10
        
         min-w-[320px] w-[80%] max-w-[500px] mx-auto my-auto ">
            <div class="flex flex-row justify-between">
                <h2 class="font-bold mb-3">Connect E-mail</h2>
                <div @click="hideModal()" class="p2 cursor-pointer" ><img src="@/assets/set/close.svg" class="w-5 h-5"></div>
                <!-- border-slate-400 rounded-full -->
            </div>

            <div v-if="isLoading" class="flex flex-col justify-center gap-3 border border-slate-600 p-5 rounded-xl mb-4 text-center min-h-[250px]">
                <div class="spinner-32 mx-auto"></div>
                <h3 class="font-bold">Loading...</h3>
            </div>
            <div v-else>
                    
                <div class="mb-5 text-lg">
                    Add a e-mail address to authenticate with your account and receive notifications.
                </div>
                

                <div v-if="signStep==1" class="flex flex-col gap-3 border border-slate-600 p-5 rounded-xl mb-4">
                    <h3 class="font-bold mb-0">E-mail</h3>
                    <!-- <div class="uppercase text-sm c2">E-mail</div> -->
                    <input type="text" class="text placeholder:text-slate-500" v-model="formData.email" placeholder="Enter your e-mail">
                    <div class="text-slate-400 text-sm">An access code will be sent to this address.</div>
                    <button target="_blank" class="b3 bc w-fit" @click="initEmail()" :disabled="isSubmit">
                        <span v-if="!isSubmit">Continue</span>
                        <span v-else>Sending...</span>
                    </button>
                    <!-- <div class="text-slate-400 text-sm">Only whitelisted accounts accepted at this time.</div> -->
                </div>

                <div v-if="signStep==2" class="flex flex-col gap-3 border border-slate-600 p-5 rounded-xl mb-4">
                    <h3 class="font-bold">Verify Code</h3>
                    <div>Enter the code sent to your e-mail:</div>
                    <input type="text" class="text" v-model="formData.code">
                    <button target="_blank" class="b3 bc w-fit" @click="sendVerify()" :disabled="isSubmit">
                        <span v-if="!isSubmit">Confirm</span>
                        <span v-else>Sending...</span>
                    </button>
                    <div>
                        <button @click="resendCode" :disabled="sendIn > 0" :class="{ 'opacity-50': sendIn > 0 }">
                            Resend code
                        </button>
                        <span class="text-slate-400 text-sm ml-2" v-if="sendIn > 0">(in {{ sendIn }} sec)</span>
                    </div>
                </div>

            </div>

        </div>

    </div> 

</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/api';

export default {
    name: 'Airpump - Sign In',
    data() {
        return {

            DEBUG: false,
            isLoading: false,
            signStep: 1,
            sendIn: 0,
            sendInNext: 30, // 30 sec
            resendTimer: null,
            isSubmit: false,
            signEmail: true,
            signWallet: false,
            signTelegram: false,
            telegramAuthCode: 'A_test',
            telegramAuthLink: null,
            sessionCode: null, // for telegram to identify session
            formData: {
                email: '', // TODO: on local make it 
                code: '',
            },

        }
    },
    computed: {

    },
    mounted() {
           // _${this.codeInvite || 'no'}_${this.utmSource || 'direct'}_${this.utmCampaign || 'no'}_${this.utmMedium || 'no'}`;
    },
    methods: {

        ...mapActions(['showInformation']),
        ...mapActions('playwallet', ['fetchWallets']),

        //* Email Auth *//

        async initEmail(resend = false) { // Added resend parameter with default value false
            this.isSubmit = true;
            try {
                if(!this.checkEmail(this.formData.email)) {
                    this.showInformation({ type: 'error', message: 'Please enter valid e-mail address' });
                    return;
                }
                const response = await api.post('settings/email/init', 
                    { data:
                        {
                            email: this.formData.email,
                            resend: resend // Include the resend parameter in the request
                        }
                    },
                );

                if(response.data.result=='success') {
                    this.formData.code=response.data.sign_code; // !! WARNING! Remove this!
                    this.showInformation({ type: 'success', message: 'Please check your e-mail' });
                    this.signStep=2;
                    this.startResendTimer();
                    return;
                }
                
            } catch (error) {
                console.error('Failed to fetch coins:', error);
                this.showInformation({ type: 'error', message: 'Failed to authorise: '+error });
                // Handle error (e.g., show notification)
            } finally {
                this.isSubmit = false;
            }
        },

        startResendTimer() {
            this.sendIn = this.sendInNext;
            this.resendTimer = setInterval(() => {
                if (this.sendIn > 0) {
                    this.sendIn--;
                } else {
                    clearInterval(this.resendTimer);
                }
            }, 1000);
        },

        async resendCode() {
            if (this.sendIn > 0) return;
            await this.initEmail(true); // Call initEmail with resend=true
        },

        async sendVerify() { // => move to store as authEmailVerify
    
            this.isSubmit = true;
            this.isLoading = true;
            try {

                const codeRegex = /^\d{6}$/;
                if (!codeRegex.test(this.formData.code)) {
                    this.showInformation({ type: 'error', message: 'Invalid code' });
                    return;
                }
               
                const response = await api.post('settings/email/verify', 
                    {  
                        data: { 
                            email: this.formData.email,
                            code: this.formData.code
                        }
                    },
                );
                // alert(JSON.stringify(response.data));
                if(response.data.result=='success') {
                    this.showInformation({ type: 'success', message: 'Welcome to Airpump!' });
                 
                    if(response.data.user_data) this.$store.commit('SET_USER', response.data.user_data);
                    // if(response.data.user_auth) {
                    //     this.$store.commit('SET_JWT', response.data.jwt);
                    //     this.$store.commit('SET_REFRESH_TOKEN', response.data.refresh_token );
                    // }

                    if (response.data.jwt && response.data.refresh_token) {
                        // alert('commit jwt'+response.data.jwt);
                        this.$store.commit('SET_JWT', response.data.jwt);
                        this.$store.commit('SET_REFRESH_TOKEN', response.data.refresh_token);
                    }

                    this.hideModal();

                    // this.$emit('showAccess');
                    // 
                    // router.push({ name: 'dashboard' });
                    //
                    return;
                }
                
            } catch (error) {
                console.error('Failed to fetch coins:', error);
                this.showInformation({ type: 'error', message: 'Failed to authorise: '+error });
                // Handle error (e.g., show notification)
            } finally {
                this.isSubmit = false;
                this.isLoading = false;
            }
            // this.showInformation({ type: 'info', text: 'Sending form...' });
        },

        //* Modal Control *//

        showConnect() {
            this.$emit('showConnect');
        },
        hideModal() {
            this.$emit('hideModal');
        },

        
    },
    beforeUnmount() {
        if (this.resendTimer) {
            clearInterval(this.resendTimer);
        }
    }
}
</script>
