<template>

    <!-- max-md:bottom-24 md:bottom-5 -->
    <div @click="hideNotification" v-if="notification" 
            :key="animationKey"
            class="z-[1001] max-w-[600px] md:w-[80%] right-3 left-3 p-2 md:p-4 md:px-3 mx-auto fixed 
             text-white text-bold text-center max-md:text-sm
             shadow-2xl rounded-[10px] md:rounded-[18px]
             border notification-animation
             bg-opacity-90  
             backdrop-blur-sm"
            :class="[
                {
                'text-[#C5DD76] border-slate-700 bg-slate-900': notification?.type === 'success',
                'text-red-200 border-red-800 bg-red-950': notification?.type === 'error',
                'text-yellow-200 border-yellow-800 bg-yellow-950': notification?.type === 'warning',
                'text-blue-200 border-blue-800 bg-blue-950': notification?.type === 'info',
                'text-slate-200 border-slate-500 bg-slate-800 ': notification?.type === 'load',
                },
                // Add dynamic bottom positioning based on notification
                information ? 'bottom-32 md:bottom-28' : 'bottom-[72px] md:bottom-12'
            ]">
            <div class="flex flex-row items-center justify-center gap-4">
                <div class="spinner" v-if="notification?.type === 'load'"></div>
                <span v-html="sanitizedMessage"></span>
            </div>
            
            <!-- Queue indicator with hover effect -->
            <div v-if="notificationQueue.length > 0" 
                 @click.stop="clearNotificationQueue" 
                 @mouseenter="showClearIcon = true" 
                 @mouseleave="showClearIcon = false"
                 class="absolute -top-2 -right-2 bg-yellow-500 hover:bg-red-500 text-black text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center cursor-pointer transition-colors duration-200">
                <span v-if="showClearIcon">✕</span>
                <span v-else>{{ notificationQueue.length }}</span>
            </div>
    </div>

</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
    props: {
        information: {
            type: Object,
            required: false,
            default: null
        },
    },
    data() {
        return {
            animationKey: 0,
            showClearIcon: false
        }
    },
    computed: {
        ...mapState(['notification', 'notificationQueue']),
        sanitizedMessage() {
            if (!this.notification?.message) return '';
            return this.notification.message;
        }
    },
    watch: {
        notification() {
            this.animationKey += 1;
        }
    },
    methods: {
        ...mapActions(['hideNotification']),
        
        clearNotificationQueue() {
            this.$store.commit('CLEAR_NOTIFICATION_QUEUE');
        }
    }
}

</script>

<style scoped>
@keyframes notificationShake {
    0%, 100% { transform: translateX(0); }
    10% { transform: translateX(-8px); }
    20% { transform: translateX(8px); }
    30% { transform: translateX(-8px); }
    40% { transform: translateX(8px); }
    50% { transform: translateX(-6px); }
    60% { transform: translateX(6px); }
    70% { transform: translateX(-4px); }
    80% { transform: translateX(4px); }
    90% { transform: translateX(-2px); }
    95% { transform: translateX(0); }
}

@keyframes notificationBlink {
    0% { background-color: inherit; }
    25% { background-color: rgba(255, 215, 0, 0.7); }
    50% { background-color: inherit; }
    75% { background-color: rgba(255, 215, 0, 0.7); }
    100% { background-color: inherit; }
}

.notification-animation {
    animation: 
        notificationShake 0.5s ease-in-out,
        notificationBlink 0.8s ease-in-out;
}
</style>
