<template>

    <div class="px-2 py-6 flex-grow flex flex-col justify-center md:mb-10 relative">

        <div class="bg-[url('@/assets/bg.jpg')] bg-cover opacity-10 absolute inset-0 z-[11]"></div> 

        <div class="relative px-10 py-6 pb-14 text-center rounded-2xl w-[80%] min-w-[320px] mx-auto bg-black bg-opacity-50 z-[100] max-w-[800px]">

            <img src="@/assets/set/chair.svg" class="w-24 h-24 my-8 mx-auto">
            <!-- <h1 class="mb-5 c3">Get Early Access</h1> -->
            <h1 class="mb-5 c3">Sign Up Today</h1>
            <!-- <h1 class="mb-5 c3">Enlist Today</h1> -->
            <!-- Claim your spot -->
            <div class="mb-6 text-xl text-slate-200" v-if="codeInvite">
                Use your invitation code to claim Early Access to Airpump platform:
                <div class="w-fit font-mono text-xs c2 opacity-70 bg-slate-700 mx-auto mt-3 px-2 py-1 rounded-md" >
                    {{ codeInvite }}
                </div>
            </div>
            <div class="mb-6 text-xl text-slate-200" v-else>
                Join the whitelist
                to reserve a spot in queue for
                Airpump platform early access.
            </div>

        
            <div class="flex gap-5 flex-col mv:flex-row justify-center">
                <button @click="showAuth()" v-if="!wallet" class="b2 bd w-fit shrink-0 whitespace-nowrap max-mv:mx-auto">
                    Enlist Now
                </button>
                <router-link :to="{ name: 'docs' }" v-if="!wallet" class="b2 bb w-fit shrink-0 whitespace-nowrap max-mv:mx-auto">
                    Read Docs
                </router-link>
            </div>
            <!-- <button @click="doConnect()" v-if="!wallet" class="b3 bc shrink-0">
                <div class="flex flex-row gap-2">
                    <span v-if="walletConnecting" class="spinner"></span>
                    Connect Wallet
                </div>
            </button> -->

            <!-- <div class="text-center">
                <h1 class="text-3xl font-bold text-zinc-500">You are not authorized to view this page</h1>
                <p class="text-lg text-zinc-500">Please log in to view this page</p>
            </div> -->

        </div>

    </div>

</template>

<script>
 import { mapState, mapActions } from 'vuex';

 export default {
    name: 'Airpump',

    data() {
        return {

            DEBUG: false,

        }
    },
    computed: {
        ...mapState(['appData', 'appLoaded', 'appError', 'appErrorCode', 'appErrorText']),
        ...mapState(['codeInvite']),
    },
    methods: {

        ...mapActions(['showInformation', 'doConnect', 'doDisconnect']),
        ...mapActions(['connectWallet', 'disconnectWallet']),
        ...mapActions(['signWallet', 'disconnectWallet']),
        ...mapActions('modals', ['showAuthModal', 'hideAuthModal']),

        showAuth() {
            this.showAuthModal();
        },

    }
}
</script>